<script setup>
import { RouterLink } from 'vue-router'
import { storeToRefs } from 'pinia'
import { usePostStore } from '../stores/post'

const { posts, loading, error, isVisible } = storeToRefs(usePostStore())
const { fetchPosts,setFalse,setTrue } = usePostStore()

fetchPosts()

function setF() {
  setFalse()
}

function setT() {
  setTrue()
}

</script>

<template>
  <main>
    <div>{{isVisible}}</div>
    <button @click="setF">set false</button>
    <button @click="setT">set true</button>
    <p v-if="loading">Loading posts...</p>
    <p v-if="error">{{ error.message }}</p>
    <div v-show="posts" v-for="post in posts" :key="post.id">
      <RouterLink @click="prevent" :to="`/post/${post.id}`">{{ post.title }}</RouterLink>
    <p @click="setT">{{ post.body }}</p>
    </div>
  

  </main>
</template>