<template>
    <div class="container">
    <form @submit.prevent="handleSubmit">
        <div class="mb-2">
            <label for="loginEmail" class="form-label">Email address</label>
            <input type="email" class="form-control" id="loginEmail" aria-describedby="" required placeholder="email"
                v-model="email">
            <div id="emailHelp" class="form-text">We'll never share your email with anyone else </div>
        </div>
        <div class="mb-2">
            <label for="loginPassword" class="form-label">Password</label>
            <input type="password" class="form-control" id="loginPassword" required placeholder="password" v-model="password">
        </div>
        <div class="mb-2">
        <button class="btn btn-primary text-center mb-3">Log in</button>
        <div v-if="error" class="alert alert-danger">{{error}}</div>
    </div>
    </form>
</div>
</template>

<script>
import { ref } from 'vue'
import useLogin from '@/composables/useLogin'

export default {
    setup(props, context) {
        //refs
        const email = ref('')
        const password = ref('')

        const {error, login} = useLogin()

        const handleSubmit = async() => {
            await login(email.value, password.value)
            if(!error.value){
                context.emit('login')
            }
        }

        return { email, password, handleSubmit, error }
    }
}
</script>