import { createApp } from 'vue'
import { createPinia } from 'pinia'
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';

import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const pinia = createPinia()
const app = createApp(App)

app.use(pinia)
app.use(router)
app.use(I18NextVue, { i18next });

app.mount('#app')
