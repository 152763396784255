<template>
    <div class="container">
        <form @submit.prevent="handleSubmit">
            <div class="mb-2">
                <label for="signupDisplayname" class="form-label">Display name</label>
                <input type="text" class="form-control" id="signupDisplayname" required placeholder="display name"
                    v-model="displayName">

            </div>
            <div class="mb-2">
                <label for="loginEmail" class="form-label">Email address</label>
                <input type="email" class="form-control" id="loginEmail" aria-describedby="" required placeholder="email"
                    v-model="email">
                <div id="emailHelp" class="form-text">We'll never share your email with anyone else </div>
            </div>
            <div class="mb-2">
                <label for="loginPassword" class="form-label">Password</label>
                <input type="password" class="form-control" id="loginPassword" required placeholder="password"
                    v-model="password">
            </div>
            <div class="mb-2">
                <button class="btn btn-primary text-center mb-3">Sign up</button>
            </div>
            <div v-if="error" class="alert alert-danger">{{error}}</div>
        </form>
    </div>
</template>

<script>
import { ref } from 'vue'
import useSignup from '../composables/useSignup'

export default {
    setup(props,context) {
        const { error, signup } = useSignup()
        //refs
        const displayName = ref('')
        const email = ref('')
        const password = ref('')

        const handleSubmit = async () => {
            await signup(email.value, password.value, displayName.value)
            if(!error.value){
                context.emit('signup')
            }
        }

        return { displayName, email, password, handleSubmit, error }
    }
}
</script>