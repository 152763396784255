import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyB-NPZ7Qdu10hWQdLaz6AnBoWWRPOMedOk",
    authDomain: "clintcontroller.firebaseapp.com",
    databaseURL: "https://clintcontroller-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "clintcontroller",
    storageBucket: "clintcontroller.appspot.com",
    messagingSenderId: "588153746918",
    appId: "1:588153746918:web:fa0032ce36152b6af95227"
};

// init firebase
firebase.initializeApp(firebaseConfig)

const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectAuth, projectFirestore, timestamp }