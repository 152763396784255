<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="text-center mt-5">Welcome to Clint Air Condition controller</div>
        <div v-if="showLogin">
          <h2 class="text-center mt-5">Login</h2>
          <LoginForm @login="enterControlPanel"/>
          <p class="text-center mt-5">No account yet? <a href="#" @click="showLogin = false">Signup </a>instead</p>
        </div>
        <div v-else>
          <h2 class="text-center mt-5">Signup</h2>
          <SignupForm @singup="enterControlPanel"/>
          <p class="text-center mt-5">Already registered? <a href="#" @click="showLogin = true">Login </a>instead</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignupForm from '../components/SignupForm.vue'
import LoginForm from '../components/LoginForm.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  components: { SignupForm, LoginForm },
  setup() {
    const showLogin = ref(true)
    const router = useRouter()

    const enterControlPanel = () => {
      router.push({name: 'ControlPanel'})
    }

    return { showLogin, enterControlPanel }
  }
}
</script>

<style></style>