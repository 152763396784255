<template>
  <header class="navbar">
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
</header>

  <router-view/>
</template>
<script setup>
import { useTranslation } from "i18next-vue";
const { t, i18next } = useTranslation();

console.log(i18next.language);
// await i18next.changeLanguage('en');
// etc.
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
